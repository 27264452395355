export const urls = {
  cityGuideUrl: '/agora-akiniton/',
  agencyUrl: '/mesitika-grafeia/',
};

export const headerUrls = [
  ...(urls.cityGuideUrl
    ? [
        {
          text: 'ftr_anchor_house_prices',
          url: urls.cityGuideUrl,
          isClientSideNavigation: true,
        },
      ]
    : []), // i18n-extract-keys ["ftr_anchor_house_prices"]
];

export const alternateHeaderUrls = [
  ...(urls.cityGuideUrl
    ? [
        {
          text: 'ftr_anchor_house_prices',
          url: urls.cityGuideUrl,
          isClientSideNavigation: true,
        },
      ]
    : []), // i18n-extract-keys ["ftr_anchor_house_prices"]
];
