import { isFeatureEnabled } from 'src/config/features-toggle';
import type { ProductConfig } from 'src/config/product';

import { CATEGORIES } from 'src/constants/typologies';
import { countryCode, domainName, language } from './constants';

import { isChristmasTime } from 'src/utils/client/christmas';
import { colors } from 'src/utils/colors';
import { isDev, isProd } from 'src/utils/environment';
import { getOrigin } from '../utils';

import { agenciesWithBaseHeaderColor } from './agenciesWithBaseHeaderColor';
import appleTouchIcon from './assets/apple-touch-icon.png';
import favicon from './assets/favicon.ico';
import favicon16 from './assets/favicon-16x16.png';
import favicon32 from './assets/favicon-32x32.png';
import logoChristmasAlt from './assets/logo-indomio-alt-natale.svg';
import logoNegative from './assets/logo-indomio-negative.svg';
import logoPositive from './assets/logo-indomio-positive.svg';
import markNegative from './assets/mark-indomio-negative.svg';
import markPositive from './assets/mark-indomio-positive.svg';
import safariPinnedTab from './assets/safari-pinned-tab.svg';
import { mapConfig } from './map';
import { alternateHeaderUrls, headerUrls, urls } from './urls';

export const config: ProductConfig = {
  ...urls,
  defaultSearchPrefix: '/pwlhsh-katoikies',
  name: 'indomio-gr',
  domainName,
  brandName: 'Indomio',
  metricsDomain: 'indomio_gr',
  origin: getOrigin(domainName),
  countryCode,
  country: 'greece',
  defaultLocale: language,
  blog: 'https://www.immobiliare.it/news/',
  allowedLanguages: {
    it: {
      code: 'it_IT',
      label: 'Italiano',
    },
    en: {
      code: 'en',
      label: 'English',
    },
    fr: {
      code: 'fr',
      label: 'Français',
    },
    de: {
      code: 'de',
      label: 'Deutsch',
    },
    es: {
      code: 'es_ES',
      label: 'Español',
    },
    pt: {
      code: 'pt',
      label: 'Português',
    },
    ru: {
      code: 'ru',
      label: 'Русский',
    },
    el: {
      code: 'el',
      label: 'Ελληνικά',
    },
  },
  phoneCountryCode: 30,
  logoConfig: {
    url: isChristmasTime() ? logoChristmasAlt : logoNegative,
    positiveUrl: logoPositive,
    width: 234,
    height: 60,
    mobile: {
      altURL: markNegative,
      positiveURL: markPositive,
      width: 26,
      height: 30,
    },
  },
  logoConfigPrintPage: {
    url: logoPositive,
    width: 146,
    height: 29,
  },
  headlineSvg: false,
  assets: {
    appleTouchIcon: appleTouchIcon.src,
    maskIcon: safariPinnedTab,
    shortcutIcon: favicon.src,
    themecolor: colors.brand['01']['500'],
    favicons: [
      {
        url: favicon32.src,
        width: 32,
        height: 32,
        type: 'image/png',
        rel: 'icon',
      },
      {
        url: favicon16.src,
        width: 16,
        height: 16,
        type: 'image/png',
        rel: 'icon',
      },
    ],
  },
  lead: {
    emailRequired: true,
    phoneRequired: true,
  },
  mapConfig,
  cityGuide: {
    mapConfig: {
      ...mapConfig,
      minZoom: 8,
    },
    geographyInfoMapZoom: {
      country: 8,
      region: 9,
      province: 10,
      city: 12,
      cityZone: 12,
      road: 16,
    },
    apiMapZoom: {
      country: 8,
      region: 9,
      province: 10,
      city: 11,
    },
  },
  geographySearch: {
    drawEnabled: true,
    metroEnabled: false,
  },
  geocodeIsolinesApiKey: 'indomio',
  geocodeReverseApiKey: 'indomio_gr',
  geocodeAddressApiKey: 'indomio_gr',
  geocodeAddressApiOrigin: 'https://www.indomio.gr',
  mapResultsLimit: 500000,
  mapResultMaxLabel: 2000,
  mapMaxResultsPerPage: 25,
  listResultsLimit: 500000,
  listResultMaxLabel: 2000,
  listMaxResultsPerPage: 25,
  energyRange: {
    high: ['A'],
    medium: ['B', 'C', 'D'],
    low: ['E', 'F', 'G'],
  },
  user: {
    publishedAdsUrl: '/utente/annunci/pubblicati/',
    savedAdsUrl: isFeatureEnabled('NEW_SAVED_LISTINGS_URL')
      ? '/user/saved/'
      : '/utente/annunci/salvati/',
    blacklistedAdsUrl: '/utente/annunci/nascosti/',
    //lastAdsUrl: '/utente/annunci/recenti/',
    savedSearchesUrl: '/user/searches/',
    //messagesUrl: '/utente/messaggi/',
    logoutUrl: '/api-next/user/logout/',
    loginUrl: '/api-next/user/login/',
    registerUrl: '/api-next/user/register/',
    passwordRecoveryUrl: '/api-next/user/password-retrieve/',
    profileUrl: '/utente/profilo/',
    editUrl: (realEstateId) => `/utente/annuncio/${realEstateId}`,
    upgradeUrl: (realEstateId) =>
      `/utente/annuncio/${realEstateId}/visibilita/`,
  },
  terms: {
    conditionUrl: '/terms/',
    privacyUrl: '/terms/privacy/',
    cookiesUrl: '/terms/cookie/',
  },
  hasFooterLinks: false,
  realEstatePath: '/aggelies/',
  facebookAppId: isDev ? 450294035457393 : 424731191372110,
  gtm: {
    id: 'GTM-W6BS39X',
  },
  mapchartImgServiceVersion: '2024071701', // remember to increase polygonsServiceVersion value
  vhostUrlCdnMaps: 'https://s1.indomio.gr/c/',
  staticMapUrl: 'https://maps.im-cdn.it/static',
  preconnectUrls: ['https://www.googletagmanager.com'],
  brazeConfig: {
    apiKey: isProd
      ? '828fc82d-143c-4ef5-8e5a-f0957b7a7256'
      : '6d805ae9-0f13-4161-9216-38e60aaf48c5',
    baseUrl: 'sdk.fra-01.braze.eu',
  },
  headerUrls,
  alternateHeaderUrls,
  discoverUrls: [],
  alternateDiscoverUrls: [],
  visibilities: {
    supervetrina: {
      label: 'lbl_supervetrina', // i18n-extract-keys ["lbl_supervetrina"]
      style: 'supervetrina',
      isMosaic: true,
    },
    vetrina: {
      label: 'lbl_vetrina', // i18n-extract-keys ["lbl_vetrina"]
      style: 'vetrina',
    },
    star: {
      label: 'lbl_star', // i18n-extract-keys ["lbl_star"]
      style: 'star',
    },
    top: {
      label: 'lbl_top', // i18n-extract-keys ["lbl_top"]
      style: 'top',
    },
    premium: {
      label: 'lbl_premium', // i18n-extract-keys ["lbl_premium"]
      style: 'premium',
    },
  },
  agenciesExpertResultsLimit: 25,
  provincePathSuffix: 'perifereia',
  homepageHeadline: {
    default: {
      transKey: 'header_default_payoff_generic', // i18n-extract-keys ["header_default_payoff_generic"]
    },
  },
  homepageExandibleCategories: [
    CATEGORIES.RESIDENZIALE,
    CATEGORIES.NUOVE_COSTRUZIONI,
    CATEGORIES.TERRENI,
  ],
  didomiNoticeID: isProd ? 'eVUnbDC4' : 'QcgPaUHX',
  oAuth: {
    google: {
      clientId:
        '709292487658-9avrk7n4qs7dg4451mlvr8bqc1j1lqce.apps.googleusercontent.com',
    },
  },
  timeZone: 'Europe/Athens',
  agenciesWithBaseHeaderColor,
  segmentConfig: isProd
    ? {
        apiHost: 'catcher.indomio.gr/v1',
        apiKey: 'LTi3EWZx6zBw4TGBwFh8F8MjMEaHrmNw',
      }
    : {
        apiHost: 'catcher-staging.indomio.gr/v1',
        apiKey: 'JafdNifUJhmVGuSSTnUhbK2b63jjStrq',
      },
  trackingReferrerRoutes: {
    publishAds: '/pubblica-annuncio/',
    userSavedListings: isFeatureEnabled('NEW_SAVED_LISTINGS_URL')
      ? '/user/saved/'
      : '/utente/annunci/salvati/',
    userSavedSearch: '/user/searches/',
    userMessages: '/utente/messaggi/',
    userHiddenAds: '/utente/annunci/nascosti/',
    userLastAds: '/utente/annunci/recenti/',
    userLastSearch: '/utente/ricerche/recenti/',
    cityGuide: '/agora-akiniton/',
    searchList: '/search-list/',
    detail: '/aggelies/',
    rent: '/enoikio-',
    sell: '/pwlhsh-',
    agency: '/mesitika-grafeia/([a-z]+)',
    agencyDetail: '/mesitika-grafeia/([0-9]+)',
  },
};
